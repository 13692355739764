import { useTranslation } from '@pancakeswap/localization'
import {
  Box,
  BunnyPlaceholderIcon,
  Button,
  CardBody,
  Flex,
  Heading,
  Skeleton,
  Text,
  useMatchBreakpoints,
  useModal,
} from '@pancakeswap/uikit'
import { LotteryStatus } from 'config/constants/types'
import { useGetUserLotteriesGraphData, useLottery } from 'state/lottery/hooks'
import { LotteryRound } from 'state/types'
import { styled } from 'styled-components'
import ViewTicketsModal from '../ViewTicketsModal'
import WinningNumbers from '../WinningNumbers'

const StyledCardBody = styled(CardBody)`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
`

const PreviousRoundCardBody: React.FC<
  React.PropsWithChildren<{ lotteryNodeData: LotteryRound; lotteryId: string }>
> = ({ lotteryNodeData, lotteryId }) => {
  const { t } = useTranslation()
  const {
    currentLotteryId,
    currentRound: { status },
  } = useLottery()
  const userLotteryData = useGetUserLotteriesGraphData()
  const userDataForRound = userLotteryData!.rounds.find((userLotteryRound) => userLotteryRound.lotteryId === lotteryId)
  const { isLg, isXl, isXxl } = useMatchBreakpoints()
  const isLargerScreen = isLg || isXl || isXxl

  const currentLotteryIdAsInt = parseInt(currentLotteryId)
  const mostRecentFinishedRoundId =
    status === LotteryStatus.CLAIMABLE ? currentLotteryIdAsInt : currentLotteryIdAsInt - 1
  const isLatestRound = mostRecentFinishedRoundId.toString() === lotteryId

  const [onPresentViewTicketsModal] = useModal(
    <ViewTicketsModal roundId={lotteryId} roundStatus={lotteryNodeData?.status} />,
  )

  const totalTicketNumber = userDataForRound ? Number(userDataForRound.totalTickets) : 0
  const ticketRoundText =
    totalTicketNumber > 1
      ? t('You had %amount% tickets this round', { amount: totalTicketNumber })
      : t('You had %amount% ticket this round', { amount: totalTicketNumber })
  const [youHadText, ticketsThisRoundText] = ticketRoundText.split(totalTicketNumber.toString())

  return (
    <StyledCardBody>
      {/* {isLatestRound && <StyledCardRibbon text={t('Latest')} />} */}
      <Flex flexDirection="column">
        <Heading textAlign="center" color="#C79A9A" mb="24px">
          Winning Number
        </Heading>
        <Flex maxWidth="100%" justifyContent="center">
          {lotteryId ? (
            lotteryNodeData?.finalNumber ? (
              <WinningNumbers
                rotateText={isLargerScreen || false}
                number={lotteryNodeData?.finalNumber.toString()}
                mr={[null, null, null, '32px']}
                size="100%"
                fontSize={isLargerScreen ? '42px' : '16px'}
              />
            ) : (
              <Skeleton
                width={['240px', null, null, '450px']}
                height={['34px', null, null, '71px']}
                mr={[null, null, null, '32px']}
              />
            )
          ) : (
            <>
              <Flex flexDirection="column" alignItems="center" width={['240px', null, null, '480px']}>
                <Text mb="8px">{t('Please specify Round')}</Text>
                <BunnyPlaceholderIcon height="64px" width="64px" />
              </Flex>
            </>
          )}
        </Flex>
        {userDataForRound && (
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Box display={['none', null, null, 'flex']}>
              <Heading textAlign="center" color="#C79A9A" mt="24px" mb="12px">
                {t('Your tickets')}
              </Heading>
            </Box>
            <Flex flexDirection="column" alignItems="center">
              <Box>
                <Text color="#C79A9A" display="inline">
                  {youHadText}{' '}
                </Text>
                <Text color="#C79A9A" display="inline" bold>
                  {userDataForRound.totalTickets}
                </Text>
                <Text color="#C79A9A" display="inline">
                  {ticketsThisRoundText}
                </Text>
              </Box>
              <Button
                onClick={onPresentViewTicketsModal}
                height="auto"
                width="fit-content"
                p="0"
                variant="text"
                scale="sm"
              >
                <Text bold color="#f66060" display="inline">
                  View your tickets
                </Text>
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </StyledCardBody>
  )
}

export default PreviousRoundCardBody
