import { useTheme } from '@pancakeswap/hooks'
import { useTranslation } from '@pancakeswap/localization'
import { Button, ButtonProps, useModal } from '@pancakeswap/uikit'
import { LotteryStatus } from 'config/constants/types'
import { useLottery } from 'state/lottery/hooks'
import styled from 'styled-components'
import BuyTicketsModal from './BuyTicketsModal/BuyTicketsModal'

interface BuyTicketsButtonProps extends ButtonProps {
  disabled?: boolean
  themeMode?: string
}

const BuyTicketsButton: React.FC<React.PropsWithChildren<BuyTicketsButtonProps>> = ({
  disabled,
  themeMode,
  ...props
}) => {
  const { t } = useTranslation()
  const { isDark } = useTheme()
  const [onPresentBuyTicketsModal] = useModal(<BuyTicketsModal />)
  const {
    currentRound: { status },
  } = useLottery()

  const getBuyButtonText = () => {
    if (status === LotteryStatus.OPEN) {
      return 'Buy Tickets'
    }
    return 'On sale soon!'
  }

  const themeStr = themeMode ?? (isDark ? 'dark' : 'light')

  return (
    <StyledBuyTicketsButton data-theme={themeStr} {...props} disabled={disabled} onClick={onPresentBuyTicketsModal}>
      <span>{getBuyButtonText()}</span>
    </StyledBuyTicketsButton>
  )
}

export default BuyTicketsButton

const StyledBuyTicketsButton = styled(Button)`
  min-width: 135px;
  height: 38px;
  border-radius: 38px;
  background: #bebebe;
  margin-top: 12px;

  &:disabled {
    background-color: #bebebe;
    color: #606060;
  }

  > span {
    font-family: 'Alibaba PuHuiTi 2' !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    line-height: 22px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: #606060;
  }
`
