import { useTranslation } from '@pancakeswap/localization'
import { Box, Flex, Heading, Skeleton, Text } from '@pancakeswap/uikit'
import { formatNumber, getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import BigNumber from 'bignumber.js'
import { useCakePrice } from 'hooks/useCakePrice'
import { useEffect, useState } from 'react'
import { getGraphLotteries } from 'state/lottery/getLotteriesData'
import { useGetLotteryGraphDataById } from 'state/lottery/hooks'
import { LotteryRound, LotteryRoundGraphEntity } from 'state/types'
import { styled } from 'styled-components'
import { getDrawnDate } from 'views/Lottery/helpers'

const NextDrawWrapper = styled(Flex)`
  width: 100%;
  padding: 24px;
`

const PreviousRoundCardHeader: React.FC<
  React.PropsWithChildren<{ locale: any; lotteryNodeData: LotteryRound; lotteryId: string }>
> = ({ locale, lotteryNodeData, lotteryId }) => {
  const { t } = useTranslation()
  const [fetchedLotteryGraphData, setFetchedLotteryGraphData] = useState<LotteryRoundGraphEntity>()
  const lotteryGraphDataFromState = useGetLotteryGraphDataById(lotteryId)
  const cakePriceBusd = useCakePrice()

  useEffect(() => {
    const getGraphData = async () => {
      const fetchedGraphData = await getGraphLotteries(undefined, undefined, { id_in: [lotteryId] })
      setFetchedLotteryGraphData(fetchedGraphData[0])
    }
    if (!lotteryGraphDataFromState) {
      getGraphData()
    }
  }, [lotteryGraphDataFromState, lotteryId])

  let prizeInBusd = new BigNumber(NaN)
  if (lotteryNodeData) {
    const { amountCollected } = lotteryNodeData
    prizeInBusd = amountCollected.times(cakePriceBusd)
  }

  const getTotalUsers = (): string => {
    if (!lotteryGraphDataFromState && fetchedLotteryGraphData) {
      return fetchedLotteryGraphData?.totalUsers
    }

    if (lotteryGraphDataFromState) {
      return lotteryGraphDataFromState?.totalUsers
    }

    return ''
  }

  const getPrizeBalances = () => {
    return (
      <>
        {prizeInBusd.isNaN() ? (
          <Skeleton my="7px" height={40} width={200} />
        ) : (
          <Heading color="#F8B607" fontSize="24px" lineHeight="1">
            ${formatNumber(getBalanceNumber(prizeInBusd), 0, 0)}
          </Heading>
        )}
        {/* {prizeInBusd.isNaN() ? (
          <Skeleton my="2px" height={14} width={90} />
        ) : (
          <Balance
            fontSize="14px"
            color="textSubtle"
            unit=" WETH"
            value={getBalanceNumber(lotteryNodeData?.amountCollected)}
            decimals={6}
          />
        )} */}
      </>
    )
  }

  return (
    <NextDrawWrapper flexDirection="column">
      <Flex alignItems="center">
        <img src="/images/blast/prize_cup.png" className="img-cup" height="64px" width="64px" alt="" />
        <Box>
          <Heading color="#9F524A" fontSize="18px">
            {t('Prize pot')}
          </Heading>
          {getPrizeBalances()}
        </Box>
      </Flex>

      <CountWrap alignItems="center">
        <Flex className="left" justifyContent="center" alignItems="center">
          {lotteryNodeData && (lotteryGraphDataFromState || fetchedLotteryGraphData) ? (
            getTotalUsers()
          ) : (
            <Skeleton height={14} width={31} />
          )}{' '}
          Players Round #{lotteryId}
        </Flex>
        <div className="middle">&nbsp;</div>
        <div className="right">
          {lotteryId ? (
            lotteryNodeData?.endTime ? (
              <Text color="#EA9494" fontSize="20px" bold>
                {t('Drawn')} {getDrawnDate(lotteryNodeData.endTime)}
              </Text>
            ) : (
              <Skeleton width="185px" height="21px" />
            )
          ) : null}
        </div>
      </CountWrap>
    </NextDrawWrapper>
  )
}

export default PreviousRoundCardHeader

const CountWrap = styled(Flex)`
  width: 718px;
  height: 54px;
  background: #351919;
  border-radius: 8px;
  padding: 0 24px;
  font-family: 'Alibaba PuHuiTi 2';
  font-size: 24px;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto;
  gap: 16px;

  > .left {
    color: #67b278;
    width: 100%;
  }
  > .middle {
    width: 1px;
    height: 28px;
    background: #6a4948;
  }
  > .right {
    color: #ea9494;
    width: 100%;
  }
`
