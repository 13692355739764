import { Box, Flex, FlexProps } from '@pancakeswap/uikit'
import random from 'lodash/random'
import { useEffect, useState } from 'react'
import { parseRetrievedNumber } from '../helpers'

interface WinningNumbersProps extends FlexProps {
  number: string
  size?: string
  fontSize?: string
  rotateText?: boolean
}

const BALL_MAP = {
  0: <img src="/images/blast/num0.png" className="img-ball" height="88px" width="88px" alt="" />,
  1: <img src="/images/blast/num1.png" className="img-ball" height="88px" width="88px" alt="" />,
  2: <img src="/images/blast/num2.png" className="img-ball" height="88px" width="88px" alt="" />,
  3: <img src="/images/blast/num3.png" className="img-ball" height="88px" width="88px" alt="" />,
  4: <img src="/images/blast/num4.png" className="img-ball" height="88px" width="88px" alt="" />,
  5: <img src="/images/blast/num5.png" className="img-ball" height="88px" width="88px" alt="" />,
  6: <img src="/images/blast/num6.png" className="img-ball" height="88px" width="88px" alt="" />,
  7: <img src="/images/blast/num7.png" className="img-ball" height="88px" width="88px" alt="" />,
  8: <img src="/images/blast/num8.png" className="img-ball" height="88px" width="88px" alt="" />,
  9: <img src="/images/blast/num9.png" className="img-ball" height="88px" width="88px" alt="" />,
}

const WinningNumbers: React.FC<React.PropsWithChildren<WinningNumbersProps>> = ({
  number,
  rotateText,
  ...containerProps
}) => {
  const [rotationValues, setRotationValues] = useState<number[]>([])
  const reversedNumber = parseRetrievedNumber(number)
  const numAsArray = reversedNumber.split('')

  useEffect(() => {
    if (rotateText && numAsArray && rotationValues.length === 0) {
      setRotationValues(numAsArray.map(() => random(-30, 30)))
    }
  }, [rotateText, numAsArray, rotationValues])

  return (
    <Flex style={{ gap: 8, margin: 0 }} justifyContent="space-between" {...containerProps}>
      {numAsArray.map((num) => {
        return <Box>{BALL_MAP[num]}</Box>
      })}
    </Flex>
  )
}

export default WinningNumbers
