import { useTranslation } from '@pancakeswap/localization'
import { Flex, IconButton } from '@pancakeswap/uikit'
import { useCallback } from 'react'
import { styled } from 'styled-components'

const StyledIconButton = styled(IconButton)`
  width: 72px;

  &:disabled {
    background: none;

    > img {
      opacity: 0.5;
    }
  }
`

interface RoundSwitcherProps {
  isLoading: boolean
  selectedRoundId: string
  mostRecentRound: number
  handleInputChange: (event: any) => void
  handleArrowButtonPress: (targetRound: number) => void
}

const RoundSwitcher: React.FC<React.PropsWithChildren<RoundSwitcherProps>> = ({
  isLoading,
  selectedRoundId,
  mostRecentRound,
  handleInputChange,
  handleArrowButtonPress,
}) => {
  const { t } = useTranslation()
  const selectedRoundIdAsInt = parseInt(selectedRoundId, 10)

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        handleInputChange(e)
      }
    },
    [handleInputChange],
  )

  return (
    <Flex
      style={{ position: 'absolute', width: '1000px', left: 0, right: 0, bottom: '-110px', margin: 'auto' }}
      alignItems="center"
      justifyContent="space-between"
    >
      {/* <Flex alignItems="center">
        <Heading mr="8px">{t('Round')}</Heading>
        <StyledInput
          pattern="^[0-9]+$"
          inputMode="numeric"
          disabled={isLoading}
          id="round-id"
          name="round-id"
          value={selectedRoundId}
          scale="lg"
          onChange={handleOnChange}
        />
      </Flex> */}
      <StyledIconButton
        disabled={!selectedRoundIdAsInt || selectedRoundIdAsInt <= 1}
        onClick={() => handleArrowButtonPress(selectedRoundIdAsInt - 1)}
        variant="text"
        mr="4px"
      >
        <img src="/images/blast/arrow_back.png" className="img-back" height="72px" width="72px" alt="" />
      </StyledIconButton>
      <StyledIconButton
        disabled={selectedRoundIdAsInt >= mostRecentRound}
        onClick={() => handleArrowButtonPress(selectedRoundIdAsInt + 1)}
        variant="text"
        mr="4px"
      >
        <img src="/images/blast/arrow_next.png" className="img-next" height="72px" width="72px" alt="" />
      </StyledIconButton>
      {/* <StyledIconButton
        disabled={selectedRoundIdAsInt >= mostRecentRound}
        onClick={() => handleArrowButtonPress(mostRecentRound)}
        variant="text"
        scale="sm"
      >
        <ArrowLastIcon />
      </StyledIconButton> */}
    </Flex>
  )
}

export default RoundSwitcher
