import { useTranslation } from '@pancakeswap/localization'
import { Box, Button, Card, CardBody, CardHeader, Flex, Text } from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { LotteryStatus } from 'config/constants/types'
import { useState } from 'react'
import { fetchLottery } from 'state/lottery/helpers'
import { useGetUserLotteriesGraphData, useLottery } from 'state/lottery/hooks'
import { LotteryRound } from 'state/types'
import { styled } from 'styled-components'
import { useAccount } from 'wagmi'
import { processLotteryResponse } from '../../helpers'
import PreviousRoundCardBody from '../PreviousRoundCard/Body'
import FinishedRoundTable from './FinishedRoundTable'

interface YourHistoryCardProps {
  handleShowMoreClick: () => void
  numUserRoundsRequested: number
}

const StyledCard = styled(Card)`
  width: 1200px;
  background: url(/images/blast/finished_bg.png);
  background-size: cover;
  height: 460px;
  position: relative;
  z-index: 1;

  > div {
    background: none;
    width: 1080px !important;
    position: relative;
    margin: 0 auto;
    margin-top: 60px;
  }
`

const StyledCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  background: none;
  width: 1080px !important;
`

const StyledHeader = styled(CardHeader)`
  background: none;
`

const YourHistoryCard: React.FC<React.PropsWithChildren<YourHistoryCardProps>> = ({
  handleShowMoreClick,
  numUserRoundsRequested,
}) => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { address: account } = useAccount()
  const [shouldShowRoundDetail, setShouldShowRoundDetail] = useState(false)
  const [selectedLotteryNodeData, setSelectedLotteryNodeData] = useState<LotteryRound>(null)
  const [selectedLotteryId, setSelectedLotteryId] = useState<string>(null)

  const {
    isTransitioning,
    currentRound: { status },
  } = useLottery()
  const userLotteryData = useGetUserLotteriesGraphData()
  const ticketBuyIsDisabled = status !== LotteryStatus.OPEN || isTransitioning

  const handleHistoryRowClick = async (lotteryId: string) => {
    setShouldShowRoundDetail(true)
    setSelectedLotteryId(lotteryId)
    const lotteryData = await fetchLottery(lotteryId)
    const processedLotteryData = processLotteryResponse(lotteryData)
    setSelectedLotteryNodeData(processedLotteryData)
  }

  // const clearState = useCallback(() => {
  //   setShouldShowRoundDetail(false)
  //   setSelectedLotteryNodeData(null)
  //   setSelectedLotteryId(null)
  // }, [])

  // const getHeader = () => {
  //   if (shouldShowRoundDetail) {
  //     return (
  //       <Flex alignItems="center">
  //         <ArrowBackIcon cursor="pointer" onClick={clearState} mr="20px" />
  //         <Flex flexDirection="column" alignItems="flex-start" justifyContent="center">
  //           <Heading scale="md" mb="4px">
  //             {t('Round')} {selectedLotteryId || ''}
  //           </Heading>
  //           {selectedLotteryNodeData?.endTime ? (
  //             <Text fontSize="14px">
  //               {t('Drawn')} {getDrawnDate(locale, selectedLotteryNodeData.endTime)}
  //             </Text>
  //           ) : (
  //             <Skeleton width="185px" height="21px" />
  //           )}
  //         </Flex>
  //       </Flex>
  //     )
  //   }

  //   return <Heading scale="md">{t('Rounds')}</Heading>
  // }

  const getBody = () => {
    if (shouldShowRoundDetail) {
      return (
        <Box mt="48px">
          <PreviousRoundCardBody lotteryNodeData={selectedLotteryNodeData} lotteryId={selectedLotteryId} />
        </Box>
      )
    }

    const claimableRounds = userLotteryData?.rounds.filter((round) => {
      return round.status.toLowerCase() === LotteryStatus.CLAIMABLE
    })

    if (!account) {
      return (
        <StyledCardBody style={{ marginTop: 60 }}>
          <Text fontSize="24px" bold textAlign="center" color="#C79A9A" mb="64px">
            Connect your wallet to check your history
          </Text>
          <ConnectWalletButton />
        </StyledCardBody>
      )
    }
    if (claimableRounds.length === 0) {
      return (
        <StyledCardBody>
          <Flex alignItems="center" justifyContent="center" flexDirection="column" maxWidth="280px" mt="64px">
            <Text bold fontSize="24px" textAlign="center" color="#c79a9a">
              {t('No lottery history found')}
            </Text>
            <BuyTicketsButton disabled={ticketBuyIsDisabled} width="100%">
              Buy tickets for the next round
            </BuyTicketsButton>
          </Flex>
        </StyledCardBody>
      )
    }
    return (
      <FinishedRoundTable
        handleHistoryRowClick={handleHistoryRowClick}
        handleShowMoreClick={handleShowMoreClick}
        numUserRoundsRequested={numUserRoundsRequested}
      />
    )
  }

  // const getFooter = () => {
  //   if (selectedLotteryNodeData) {
  //     return <PreviousRoundCardFooter lotteryNodeData={selectedLotteryNodeData} lotteryId={selectedLotteryId} />
  //   }
  //   return (
  //     <CardFooter>
  //       <Flex flexDirection="column" justifyContent="center" alignItems="center">
  //         <Text fontSize="12px" color="textSubtle">
  //           {t('Only showing data for Lottery V2')}
  //         </Text>
  //       </Flex>
  //     </CardFooter>
  //   )
  // }

  return (
    <StyledCard>
      {/* <StyledHeader>{getHeader()}</StyledHeader> */}
      {getBody()}
      {/* {getFooter()} */}
    </StyledCard>
  )
}

export default YourHistoryCard

const BuyTicketsButton = styled(Button)`
  width: 352px;
  height: 64px;
  border-radius: 8px;
  background: #9c504d;
  font-size: 18px;
  margin-top: 36px;

  &:disabled {
    background: #9c504d;
    opacity: 0.7;
  }
`
