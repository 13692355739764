import { useTranslation } from '@pancakeswap/localization'
import { Balance, Box, Flex, Skeleton } from '@pancakeswap/uikit'
import { getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import { LotteryStatus } from 'config/constants/types'
import { useCakePrice } from 'hooks/useCakePrice'
import { useLottery } from 'state/lottery/hooks'
import { keyframes, styled } from 'styled-components'
import BuyTicketsButton from './BuyTicketsButton'

export const floatingStarsLeft = keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  to {
    transform: translate(0, -0px);
  }
`

export const floatingStarsRight = keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  to {
    transform: translate(0, -0px);
  }
`

const floatingTicketLeft = keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-10px, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
`

const floatingTicketRight = keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(10px, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
`

const mainTicketAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(6deg);
  }
  75% {
    transform: rotate(-6deg);
  }
  to {
    transform: rotate(0deg);
  }
`

const TicketContainer = styled(Flex)`
  &:hover {
    animation: ${mainTicketAnimation} 3s ease-in-out infinite;
  }
`

const PrizeTotalBalance = styled(Balance)`
  font-family: 'Alibaba PuHuiTi 2';
  font-size: 48px;
  font-weight: 800;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: center;
  color: #f8b607;
`

const StyledBuyTicketButton = styled(BuyTicketsButton) <{ disabled: boolean }>`
  width: 100%;
  height: 100%;
  background: none;
  font-family: 'Alibaba PuHuiTi 2';
  font-size: 24px;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #606060;
  margin-top: 0;

  >span {
    font-family: 'Alibaba PuHuiTi 2' !important;
    font-size: 24px !important;
    font-weight: 800 !important;
    line-height: 34px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: #606060 !important;
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0);
    color: #606060;
  }
`

const ButtonWrapper = styled.div`
  width: 254px;
  height: 82px;
  margin-top: 50px;
  background: url(/images/blast/main_button.png);
  background-size: contain;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
`

const TicketSvgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-4deg);
`

const Decorations = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(/images/decorations/bg-star.svg);
  background-repeat: no-repeat;
  background-position: center 0;
`

const StarsDecorations = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;

  & img {
    position: absolute;
  }

  & :nth-child(1) {
    animation: ${floatingStarsLeft} 3s ease-in-out infinite;
    animation-delay: 0.25s;
  }
  & :nth-child(2) {
    animation: ${floatingStarsLeft} 3.5s ease-in-out infinite;
    animation-delay: 0.5s;
  }
  & :nth-child(3) {
    animation: ${floatingStarsRight} 4s ease-in-out infinite;
    animation-delay: 0.75s;
  }
  & :nth-child(4) {
    animation: ${floatingTicketLeft} 6s ease-in-out infinite;
    animation-delay: 0.2s;
  }
  & :nth-child(5) {
    animation: ${floatingTicketRight} 6s ease-in-out infinite;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & :nth-child(1) {
      left: 3%;
      top: 42%;
    }
    & :nth-child(2) {
      left: 9%;
      top: 23%;
    }
    & :nth-child(3) {
      right: 2%;
      top: 24%;
    }
    & :nth-child(4) {
      left: 8%;
      top: 67%;
    }
    & :nth-child(5) {
      right: 8%;
      top: 67%;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    & :nth-child(1) {
      left: 10%;
      top: 42%;
    }
    & :nth-child(2) {
      left: 17%;
      top: 23%;
    }
    & :nth-child(3) {
      right: 10%;
      top: 24%;
    }
    & :nth-child(4) {
      left: 17%;
      top: 67%;
    }
    & :nth-child(5) {
      right: 17%;
      top: 67%;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    & :nth-child(1) {
      left: 19%;
      top: 42%;
    }
    & :nth-child(2) {
      left: 25%;
      top: 23%;
    }
    & :nth-child(3) {
      right: 19%;
      top: 24%;
    }
    & :nth-child(4) {
      left: 24%;
      top: 67%;
    }
    & :nth-child(5) {
      right: 24%;
      top: 67%;
    }
  }
`

const Hero = () => {
  const { t } = useTranslation()
  const { currentRound, isTransitioning } = useLottery()

  const { amountCollected, status } = currentRound

  const cakePriceBusd = useCakePrice()
  const prizeInBusd = amountCollected.times(cakePriceBusd)
  const prizeTotal = getBalanceNumber(prizeInBusd)
  const ticketBuyIsDisabled = status !== LotteryStatus.OPEN || isTransitioning

  const getHeroHeading = () => {
    if (status === LotteryStatus.OPEN) {
      return (
        <>
          {prizeInBusd.isNaN() ? (
            <Skeleton my="7px" height={60} width={190} />
          ) : (
            <PrizeTotalBalance fontSize="64px" bold prefix="$" value={prizeTotal} mb="8px" decimals={6} />
          )}
        </>
      )
    }
    return <Heading>Tickets are sale soon</Heading>
  }

  return (
    <HeroContainer>
      <CupWrap>
        <img src="/images/blast/hero_decorations.png" className="img-decorations" height="196px" width="434px" alt="" />
        <img src="/images/blast/cup.png" className="img-cup" height="231px" width="152px" alt="" />
      </CupWrap>
      <div className="title-line">BlastLottery Prize</div>
      {getHeroHeading()}
      <TicketContainer
        position="relative"
        width={['240px', '288px']}
        height={['94px', '113px']}
        alignItems="center"
        justifyContent="center"
      >
        <ButtonWrapper>
          <StyledBuyTicketButton disabled={ticketBuyIsDisabled}>Buy Tickets</StyledBuyTicketButton>
        </ButtonWrapper>
      </TicketContainer>
    </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.div`
  width: 538px;
  height: 580px;
  background: url(/images/blast/hero_bg.png);
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  > .title-line {
    font-family: 'Alibaba PuHuiTi 2';
    font-size: 24px;
    font-weight: 800;
    line-height: 34px;
    text-align: center;
    color: #e3e7b1;
    margin-bottom: 24px;
  }
`

const CupWrap = styled.div`
  width: 100%;
  height: 196px;

  > .img-decorations {
    position: absolute;
    width: 434px;
    height: 196px;
    right: 10px;
    top: 4px;
  }

  > .img-cup {
    position: absolute;
    width: 231px;
    height: 152px;
    left: 0;
    top: 60px;
    right: 0;
    margin: auto;
  }
`

const Heading = styled.div`
  font-family: 'Alibaba PuHuiTi 2';
  font-size: 36px;
  font-weight: 800;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: center;
  color: #f8b607;
`
