import { useTranslation } from '@pancakeswap/localization'
import { AutoRenewIcon, Box, Button, Flex, Modal, Text } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import { useCallback } from 'react'
import { styled } from 'styled-components'
import TicketInput from './TicketInput'
import { Ticket, UpdateTicketAction } from './useTicketsReducer'

const StyledModal = styled(Modal)`
  max-height: 552px;
  & div:nth-child(2) {
    padding: 0;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 280px;
  }
`

const ScrollableContainer = styled.div`
  height: 310px;
  overflow-y: scroll;
  border-bottom: 1px solid #50512D;
  padding: 24px;
`

const EditNumbersModal: React.FC<
  React.PropsWithChildren<{
    totalCost: string
    updateTicket: UpdateTicketAction
    randomize: () => void
    tickets: Ticket[]
    allComplete: boolean
    onConfirm: () => void
    isConfirming: boolean
    onDismiss?: () => void
  }>
> = ({ totalCost, updateTicket, randomize, tickets, allComplete, onConfirm, isConfirming, onDismiss }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const handleOnConfirm = useCallback(() => onConfirm(), [onConfirm])
  return (
    <StyledModal
      minWidth={400}
      title={t('Edit numbers')}
      onDismiss={onDismiss}
      headerBackground='none'
      onBack={onDismiss}
    >
      <ScrollableContainer>
        <Flex justifyContent="space-between" mb="16px">
          <Text fontSize="18px" color="#B4C498" bold>Total cost:</Text>
          <Text fontSize="18px" color="#FBB904" bold>~{totalCost} WETH</Text>
        </Flex>
        <Text fontSize="14px" color="#FBB904" mb="16px">
          Numbers are randomized, with no duplicates among your tickets. Tap a number to edit it. Available digits: 0-9
        </Text>
        <Button disabled={isConfirming} mb="16px" style={{ borderColor: '#31ccb4', color: '#31ccb4', fontSize: '14px' }} variant="secondary" width="100%" height="32px" onClick={randomize}>
          Randomize
        </Button>
        {tickets.map((ticket) => (
          <TicketInput
            key={ticket.id}
            ticket={ticket}
            duplicateWith={ticket.duplicateWith}
            updateTicket={updateTicket}
            disabled={isConfirming}
          />
        ))}
      </ScrollableContainer>
      <Flex flexDirection="column" justifyContent="center" m="24px">
        <Button
          id="lotteryBuyEdited"
          disabled={!allComplete || isConfirming}
          endIcon={isConfirming ? <AutoRenewIcon spin color="currentColor" /> : undefined}
          style={{ height: 64, borderRadius: 8, background: '#31ccb4', color: '#000', fontSize: '18px', fontWeight: 'bold' }}
          onClick={handleOnConfirm}
        >
          {isConfirming ? 'Confirming' : 'Confirm and buy'}
        </Button>
        <Button mt="8px" variant={isConfirming ? 'secondary' : 'secondary'} style={{ borderRadius: '8px', height: '64px', borderColor: '#31ccb4', fontWeight: 'bold', fontSize: '18px', color: '#31ccb4' }} disabled={isConfirming} onClick={onDismiss}>
          <Box mr="8px"><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z" stroke="#31CCB4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 8.5L8 12.5L12 16.5" stroke="#31CCB4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 12.5H8" stroke="#31CCB4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          </Box> {t('Go back')}
        </Button>
      </Flex>
    </StyledModal>
  )
}

export default EditNumbersModal
