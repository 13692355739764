import { useTranslation } from '@pancakeswap/localization'
import { BalanceInput, Button, Text, useToast } from '@pancakeswap/uikit'
import { parseUnits } from '@pancakeswap/utils/viem/parseUnits'
import BigNumber from 'bignumber.js'
import { lotteryV2ABI } from 'config/abi/lotteryV2'
import { useCallback, useMemo, useState } from 'react'
import { styled } from 'styled-components'
import { getLotteryV2Address } from 'utils/addressHelpers'
import { useAccount, useContractWrite } from 'wagmi'

export interface WithdrawData {
  amount: string,
  lastAttendedLotteryId: string,
  awaitRound: string
  refetch: () => void
  hasData: boolean
}

const WithdrawCard = ({ withdrawData }: { withdrawData: WithdrawData }) => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { address: account } = useAccount()
  const [isExpanded, setIsExpanded] = useState(false)
  const { toastSuccess, toastError } = useToast()


  const [withdrawVal, setWithdrawVal] = useState('')

  const validateInput = useCallback(
    (inputNumber: BigNumber) => {
      return true
    },
    [],
  )

  const isDisabled = useMemo(() => {
    if (!withdrawData.hasData) {
      return true
    }
    if ((+withdrawData.awaitRound) > 0) {
      return true
    }
    return false
  }, [withdrawData, withdrawVal])

  const handleInputChange = (input: string) => {
    // Force input to integer
    // const inputAsInt = parseInt(input, 10)
    const inputAsBN = new BigNumber(input)
    if (inputAsBN.gt(withdrawData.amount)) {
      setWithdrawVal(withdrawData.amount)
      return
    }
    setWithdrawVal(input)
  }

  const { writeAsync: withdraw, isLoading, isSuccess } = useContractWrite({
    address: getLotteryV2Address(),
    abi: lotteryV2ABI,
    functionName: 'withdrawAssets',
    args: [parseUnits(withdrawVal, 18)]
  })

  const handleWithdraw = async () => {
    const result = await withdraw().catch(err => {
      console.log(err, 'check withdraw err')
      toastError(err.message || 'Withdraw failed.')
    })

    if (result) {
      toastSuccess('Withdraw success!')
      setWithdrawVal('')
      setTimeout(() => {
        withdrawData.refetch()
      }, 5000)
    }
  }

  return (
    <NextDrawWrapper className={isExpanded ? 'show' : ''}>
      <StyledCard>
        <WithdrawHeader>
          Waiting Time: <span>{withdrawData.hasData ? withdrawData.awaitRound : '-'} Round</span>
        </WithdrawHeader>
        <MainContent>

          <div className="balance-line">
            <div className="left">{withdrawData.hasData ? withdrawData.amount : '0'} <span>WETH</span></div>
            <div className="right">Assets balance</div>
          </div>

          <BalanceInput
            width="400px"
            className='withdraw'
            isWarning={false}
            decimals={6}
            disabled={isLoading || isDisabled}
            placeholder="0.00"
            textAlign='left'
            value={withdrawVal}
            onUserInput={handleInputChange}
            appendComponent={<MaxButton onClick={() => {
              if (isDisabled) {
                return
              }
              setWithdrawVal(withdrawData.amount)
            }}>MAX</MaxButton>}
          />

          <Button isLoading={isLoading} disabled={!withdrawVal || isDisabled} onClick={async () => { handleWithdraw() }} className="switch-button">
            <Text color="#fff" fontWeight="bold" p="6px" textAlign="center">
              Withdraw
            </Text>
          </Button>
        </MainContent>
      </StyledCard>
    </NextDrawWrapper>
  )
}

export default WithdrawCard

const StyledCard = styled.div`
  width: 538px;
  height: 412px;
  position: relative;
  z-index: 1;
  background: url(/images/blast/withdraw_bg.png);
  background-size: contain;
  padding-top: 36px;
  background-color: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 28px;
`

const MaxButton = styled.div`
  width: 80px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content :center;
  border-radius: 28px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #3A95CB;
  opacity: 0.9;
  
  &:hover {
    opacity: 1;
  }
`

const NextDrawWrapper = styled.div`
  width: 538px;
  height: 412px;
  position: relative;
  transition: all 0.5s;
`

const WithdrawHeader = styled.div`
  width: 100%;
  height: 56px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;

  >span {
    margin-left: 12px;
    color: #79B07D;
  }
`

const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .balance-line {
    width: 400px;
    height: 60px;
    border-radius: 8px;
    margin-top: 18px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: #1B3341;

    >.left {
      font-family: Alibaba PuHuiTi 2.0;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
      color: #F8B607;

      > span {
        colr: #9DE1FF;
      }
    }
    >.right {
      color: #4B788B;
      font-family: Alibaba PuHuiTi 2.0;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .switch-button {
    width: 120px;
    height: 40px;
    background-size: contain;
    margin: 0 auto;
    background: #628BA0;
    margin-top: 40px;
    color: #fff;

    &:disabled {
      opacity: 0.5;
    }
  }
`
