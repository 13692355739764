import { useTranslation } from '@pancakeswap/localization'
import { Button, CardHeader, Flex, Text } from '@pancakeswap/uikit'
import { LotteryStatus } from 'config/constants/types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchLottery } from 'state/lottery/helpers'
import { useLottery } from 'state/lottery/hooks'
import { LotteryRound } from 'state/types'
import { styled } from 'styled-components'
import { processLotteryResponse } from '../../helpers'
import PreviousRoundCardBody from '../PreviousRoundCard/Body'
import PreviousRoundCardFooter from '../PreviousRoundCard/FooterExpanded'
import PreviousRoundCardHeader from '../PreviousRoundCard/HeaderLine'
import RoundSwitcher from './RoundSwitcher'

const StyledCard = styled(Flex)`
  width: 1200px;
  background: url(/images/blast/finished_bg.png);
  background-size: cover;
  height: 460px;
  position: relative;
  z-index: 1;
`

const StyledCardHeader = styled(CardHeader)`
  width: 100%;
  z-index: 2;
  background: none;
  position: relative;
  padding: 0 30px;
`

const AllHistoryCard = () => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    currentLotteryId,
    lotteriesData,
    currentRound: { status, isLoading },
  } = useLottery()
  const [latestRoundId, setLatestRoundId] = useState<number | null>(null)
  const [selectedRoundId, setSelectedRoundId] = useState('')
  const [selectedLotteryNodeData, setSelectedLotteryNodeData] = useState<LotteryRound | null>(null)
  const timer = useRef<any>()

  const numRoundsFetched = lotteriesData?.length

  useEffect(() => {
    if (currentLotteryId && parseInt(currentLotteryId)) {
      const currentLotteryIdAsInt = parseInt(currentLotteryId)
      const mostRecentFinishedRoundId =
        status === LotteryStatus.CLAIMABLE ? currentLotteryIdAsInt : currentLotteryIdAsInt - 1
      setLatestRoundId(mostRecentFinishedRoundId)
      setSelectedRoundId(mostRecentFinishedRoundId.toString())
    }
  }, [currentLotteryId, status])

  useEffect(() => {
    setSelectedLotteryNodeData(null)

    const fetchLotteryData = async () => {
      const lotteryData = await fetchLottery(selectedRoundId)
      const processedLotteryData = processLotteryResponse(lotteryData)
      setSelectedLotteryNodeData(processedLotteryData)
    }

    timer.current = setInterval(() => {
      if (selectedRoundId) {
        fetchLotteryData()
      }
      clearInterval(timer.current)
    }, 1000)

    return () => clearInterval(timer.current)
  }, [selectedRoundId, currentLotteryId, numRoundsFetched, dispatch])

  const handleInputChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event
      if (value) {
        setSelectedRoundId(value)
        if (parseInt(value, 10) <= 0) {
          setSelectedRoundId('')
        }
        if (parseInt(value, 10) >= latestRoundId!) {
          setSelectedRoundId(latestRoundId!.toString())
        }
      } else {
        setSelectedRoundId('')
      }
    },
    [latestRoundId],
  )

  const handleArrowButtonPress = useCallback((targetRound) => {
    if (targetRound) {
      setSelectedRoundId(targetRound.toString())
    } else {
      // targetRound is NaN when the input is empty, the only button press that will trigger this func is 'forward one'
      setSelectedRoundId('1')
    }
  }, [])

  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (!selectedRoundId) {
      setIsExpanded(false)
    }
  }, [selectedRoundId])

  return (
    <HistoryWrap className={isExpanded ? 'show' : ''} style={{ position: 'relative', zIndex: 5 }}>
      <StyledCard flexDirection="column">
        <StyledCardHeader>
          <PreviousRoundCardHeader
            locale={locale}
            lotteryNodeData={selectedLotteryNodeData!}
            lotteryId={selectedRoundId}
          />

          <RoundSwitcher
            isLoading={!!isLoading}
            selectedRoundId={selectedRoundId}
            mostRecentRound={latestRoundId!}
            handleInputChange={handleInputChange}
            handleArrowButtonPress={handleArrowButtonPress}
          />
        </StyledCardHeader>
        <PreviousRoundCardBody lotteryNodeData={selectedLotteryNodeData!} lotteryId={selectedRoundId} />

        <Flex p="8px 24px" mt="36px" alignItems="center" justifyContent="center">
          <StyledButton
            onClick={() => {
              if (selectedRoundId) {
                setIsExpanded(!isExpanded)
              }
            }}
            className="switch-button"
          >
            <Text color="#5B3E22" fontWeight="bold" p="6px" textAlign="center">
              {isExpanded ? 'Hide' : 'Details'}
            </Text>
          </StyledButton>
        </Flex>
      </StyledCard>
      {/* <PreviousRoundCardFooter lotteryNodeData={selectedLotteryNodeData!} lotteryId={selectedRoundId} /> */}

      <StyledExpandCard className={isExpanded ? 'show' : ''}>
        <PreviousRoundCardFooter lotteryNodeData={selectedLotteryNodeData!} lotteryId={selectedRoundId!} />
      </StyledExpandCard>
    </HistoryWrap>
  )
}

export default AllHistoryCard

const HistoryWrap = styled.div`
  height: 500px;
  transition: all 0.5s;
  position: relative;

  &.show {
    height: 1000px;
  }
`

const StyledButton = styled(Button)`
  width: 120px;
  height: 40px;
  background: url(/images/blast/switch_button.png);
  background-size: contain;
  margin: 0 auto;
`

const StyledExpandContainer = styled.div`
  width: 720px;
  height: 580px;
  position: relative;
  z-index: 1;
  background: url(/images/blast/next_draw_bg.png);
  background-size: contain;
  padding-top: 64px;
  background-color: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`

const StyledExpandCard = styled(StyledExpandContainer)`
  width: 720px;
  height: 580px;
  background: red;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url(/images/blast/history_extend.png);
  background-size: cover;
  transform: translateY(0);
  transition: all 0.5s;
  z-index: 0;
  opacity: 0;

  &.show {
    transform: translateY(400px);
    opacity: 1;
  }
`
