import { useTranslation } from '@pancakeswap/localization'
import { Balance, Button, CardFooter, Flex, Heading, Skeleton, Text, useModal } from '@pancakeswap/uikit'
import { getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import { LotteryStatus } from 'config/constants/types'
import { useCakePrice } from 'hooks/useCakePrice'
import { useState } from 'react'
import { useLottery } from 'state/lottery/hooks'
import { styled } from 'styled-components'
import { useAccount } from 'wagmi'
import { dateTimeOptions } from '../helpers'
import BuyTicketsButton from './BuyTicketsButton'
import RewardBrackets from './RewardBrackets'
import ViewTicketsModal from './ViewTicketsModal'

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-column-gap: 32px;
    grid-template-columns: auto 1fr;
  }
`

const RewardsWrapper = styled.div`
  padding: 24px;
`

const NextDrawCard = () => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { address: account } = useAccount()
  const { currentLotteryId, isTransitioning, currentRound } = useLottery()
  const { endTime, amountCollected, userTickets, status } = currentRound
  const [onPresentViewTicketsModal] = useModal(<ViewTicketsModal roundId={currentLotteryId} roundStatus={status} />)
  const [isExpanded, setIsExpanded] = useState(false)

  const ticketBuyIsDisabled = status !== LotteryStatus.OPEN || isTransitioning

  const cakePriceBusd = useCakePrice()
  const prizeInBusd = amountCollected.times(cakePriceBusd)
  const endTimeMs = parseInt(endTime, 10) * 1000
  const endDate = new Date(endTimeMs)
  const isLotteryOpen = status === LotteryStatus.OPEN
  const userTicketCount = userTickets?.tickets?.length || 0

  const getPrizeBalances = () => {
    if (status === LotteryStatus.CLOSE || status === LotteryStatus.CLAIMABLE) {
      return (
        <Heading scale="xl" color="#F8B607" textAlign={['center', null, null, 'left']}>
          {t('Calculating')}...
        </Heading>
      )
    }
    return (
      <>
        {prizeInBusd.isNaN() ? (
          <Skeleton my="7px" height={40} width={160} />
        ) : (
          <Balance
            fontSize="40px"
            color="#F8B607"
            textAlign="center"
            lineHeight="1"
            bold
            prefix="~$"
            value={getBalanceNumber(prizeInBusd)}
            decimals={0}
          />
        )}
        {prizeInBusd.isNaN() ? (
          <Skeleton my="2px" height={14} width={90} />
        ) : (
          <Balance
            bold
            mt="8px"
            fontSize="18px"
            textAlign="center"
            unit=" WETH"
            color="#5E87D3"
            value={getBalanceNumber(amountCollected)}
            decimals={6}
          />
        )}
      </>
    )
  }

  const getNextDrawId = () => {
    if (status === LotteryStatus.OPEN) {
      return `${currentLotteryId} |`
    }
    if (status === LotteryStatus.PENDING) {
      return ''
    }
    return parseInt(currentLotteryId, 10) + 1
  }

  const getNextDrawDateTime = () => {
    if (status === LotteryStatus.OPEN) {
      return `${t('Draw')}: ${endDate.toLocaleString(locale, dateTimeOptions)}`
    }
    return ''
  }

  const ticketRoundText =
    userTicketCount > 1
      ? `You have ${userTicketCount} tickets this round`
      : `You have ${userTicketCount} ticket this round`
  const [youHaveText, ticketsThisRoundText] = ticketRoundText.split(userTicketCount.toString())

  return (
    <NextDrawWrapper className={isExpanded ? 'show' : ''}>
      <StyledCard>
        <CardHeader>
          <Flex alignItems="center" justifyContent="center">
            <img src="/images/blast/time.png" className="img-cup" height="48px" width="48px" alt="" />
            <Heading color="#D6C5AB" bold mr="12px">
              {t('Next Draw')}
            </Heading>
          </Flex>
        </CardHeader>
        <MainContent>
          <div className="date-line">
            <span>{currentLotteryId && `#${getNextDrawId()}`}</span>
            {Boolean(endTime) && getNextDrawDateTime()}
          </div>

          <Flex className="tickets-block" flexDirection="column" alignItems="center" justifyContent="center">
            <Heading fontSize="24px" color="#ccb57e" mb="12px">
              Your tickets
            </Heading>
            {isLotteryOpen && (
              <Flex flexDirection="row" alignItems="center">
                {account && (
                  <Flex justifyContent={['center', null, null, 'flex-start']}>
                    <Text color="#A1907D" display="inline">
                      {youHaveText}{' '}
                    </Text>
                    {!userTickets!.isLoading ? (
                      <Balance value={userTicketCount} decimals={0} color="#fff" display="inline" bold mx="4px" />
                    ) : (
                      <Skeleton mx="4px" height={20} width={40} />
                    )}
                    <Text color="#A1907D" display="inline">
                      {' '}
                      {ticketsThisRoundText}
                    </Text>
                  </Flex>
                )}
                {!userTickets!.isLoading && userTicketCount > 0 && (
                  <Button
                    onClick={onPresentViewTicketsModal}
                    height="auto"
                    width="fit-content"
                    p="0"
                    ml="8px"
                    variant="text"
                    scale="sm"
                  >
                    <Text color="#5E87D3">View</Text>
                  </Button>
                )}
              </Flex>
            )}
            <BuyTicketsButton disabled={ticketBuyIsDisabled} maxWidth="280px" />
          </Flex>

          <Flex className="prize-block" flexDirection="column" alignItems="center" justifyContent="center">
            <Heading mb="12px" color="#CCB57E" fontSize="24px">
              Prize Pot
            </Heading>
            <Flex flexDirection="column">{getPrizeBalances()}</Flex>
          </Flex>
          <Button onClick={() => setIsExpanded((v) => !v)} className="switch-button">
            {(status === LotteryStatus.OPEN || status === LotteryStatus.CLOSE) && (
              <Text color="#5B3E22" fontWeight="bold" p="6px" textAlign="center">
                {isExpanded ? t('Hide') : t('Details')}
              </Text>
            )}
          </Button>
        </MainContent>
        <CardFooter p="0">
          {/* {isExpanded && (
            <RewardsWrapper>
              <RewardBrackets lotteryNodeData={currentRound} />
            </RewardsWrapper>
          )} */}
        </CardFooter>
      </StyledCard>
      <StyledExpandCard className={isExpanded ? 'show' : ''}>
        <RewardsWrapper>
          <RewardBrackets lotteryNodeData={currentRound} />
        </RewardsWrapper>
      </StyledExpandCard>
    </NextDrawWrapper>
  )
}

export default NextDrawCard

const StyledCard = styled.div`
  width: 538px;
  height: 580px;
  position: relative;
  z-index: 1;
  background: url(/images/blast/next_draw_bg.png);
  background-size: contain;
  padding-top: 64px;
  background-color: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`

const NextDrawWrapper = styled.div`
  width: 538px;
  height: 580px;
  position: relative;
  transition: all 0.5s;

  &.show {
    height: 1160px;
  }
`

const StyledExpandCard = styled(StyledCard)`
  width: 538px;
  height: 580px;
  background: red;
  position: absolute;
  top: 0;
  left: 0;
  background: url(/images/blast/next_draw_extend.png);
  background-size: contain;
  transform: translateY(0);
  transition: all 0.5s;
  z-index: 0;

  &.show {
    transform: translateY(520px);
  }
`

const CardHeader = styled.div`
  width: 203px;
  height: 56px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  border-radius: 0px 0px 8px 8px;
  background: #825d35;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  > .date-line {
    margin-bottom: 16px;
    font-family: 'Alibaba PuHuiTi 2';
    font-size: 24px;
    font-weight: 800;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #d6c5ab;

    > span {
      color: #67b278;
    }
  }

  .tickets-block {
    width: 400px;
    height: 160px;
    background: #382e23;
    border-radius: 8px;
    padding: 18px;
  }

  .prize-block {
    width: 400px;
    height: 140px;
    background: #382e23;
    border-radius: 8px;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 32px;
  }

  .switch-button {
    width: 120px;
    height: 40px;
    background: url(/images/blast/switch_button.png);
    background-size: contain;
    margin: 0 auto;
  }
`
