import { ButtonMenu, ButtonMenuItem } from '@pancakeswap/uikit'
import styled from 'styled-components'

const HistoryTabMenu = ({ setActiveIndex, activeIndex }) => {
  return (
    <StyledSwitchWrap activeIndex={activeIndex} onItemClick={setActiveIndex} variant="subtle">
      <StyledSwitchButton className={activeIndex === 0 ? 'active' : ''}>All History</StyledSwitchButton>
      <div className="divider">&nbsp;</div>
      <StyledSwitchButton className={activeIndex === 2 ? 'active' : ''}>Your History</StyledSwitchButton>
    </StyledSwitchWrap>
  )
}

export default HistoryTabMenu

const StyledSwitchWrap = styled(ButtonMenu)`
  background: url(/images/blast/history_switch.png);
  background-size: cover;
  width: 720px;
  height: 105px;
  border: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 222px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 6;

  > .divider {
    width: 1px;
    height: 34px;
    background: #ffc8c8;
    margin: 0 24px;
  }
`

const StyledSwitchButton = styled(ButtonMenuItem)`
  background: none;
  width: 122px !important;
  padding: 0;
  height: 34px !important;
  flex: none !important;
  color: #ffc8c8 !important;
  opacity: 0.4;

  &.active {
    opacity: 1;
  }
`
