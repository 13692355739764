import { Button } from '@pancakeswap/uikit'
import { styled } from 'styled-components'

interface NumTicketsToBuyButtonProps {
  onClick: () => void
  disabled?: boolean
}

const StyledButton = styled(Button)`
  height: 28px;
  background: #373c2f;
  border-radius: 4px;
  color: #fff;
  flex-grow: 1;
  font-size: 14px;

  &:hover {
    border: solid 1px #fbb904;
    color: #fbb904;
  }
`

const NumTicketsToBuyButton: React.FC<React.PropsWithChildren<NumTicketsToBuyButtonProps>> = ({
  children,
  onClick,
  disabled = false,
}) => {
  return (
    <StyledButton disabled={disabled} scale="xs" mx="2px" p="4px 16px" variant="tertiary" onClick={onClick}>
      {children}
    </StyledButton>
  )
}

export default NumTicketsToBuyButton
