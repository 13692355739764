import { useTranslation } from '@pancakeswap/localization'
import { Box, Flex, SmallDotIcon, Text } from '@pancakeswap/uikit'
import { styled } from 'styled-components'
import { dateOptions, timeOptions } from '../../helpers'

interface FinishedRoundRowProps {
  roundId: string
  numberTickets: string
  endTime: string
  onClick: (string) => void
  hasWon?: boolean
}

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  margin-bottom: 8px;
  cursor: pointer;
`

const StyledSmallDotIcon = styled(SmallDotIcon)`
  path {
    fill: ${({ theme }) => theme.colors.textDisabled};
  }
`

const FinishedRoundRow: React.FC<React.PropsWithChildren<FinishedRoundRowProps>> = ({
  roundId,
  numberTickets,
  endTime,
  onClick,
  hasWon = false,
}) => {
  const {
    currentLanguage: { locale },
  } = useTranslation()
  const endTimeInMs = parseInt(endTime, 10) * 1000
  const endTimeAsDate = new Date(endTimeInMs)

  return (
    <Grid
      style={{ background: '#351919', height: 54, padding: '0 16px', borderRadius: 8, marginBottom: 12 }}
      onClick={() => onClick(roundId)}
    >
      <Flex alignItems="center">
        <Text fontSize="24px" bold color="#EA9494">
          {roundId}
        </Text>
      </Flex>
      <Flex
        ml="12px"
        alignItems={['flex-start', null, 'center']}
        justifyContent={['center', null, 'flex-start']}
        flexDirection={['column', null, 'row']}
      >
        <Text fontSize="24px" bold color="#EA9494">
          {endTimeAsDate.toLocaleDateString(locale, dateOptions)}
        </Text>
        &nbsp;
        <Text fontSize="24px" bold color="#EA9494">
          {endTimeAsDate.toLocaleTimeString(undefined, timeOptions)}
        </Text>
      </Flex>
      <Flex ml="24px" alignItems="center" justifyContent="space-between">
        <Text fontSize="24px" bold color="#EA9494">
          {numberTickets}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Text fontSize="24px" bold color="#F4B126">
          Detail
        </Text>
      </Flex>
    </Grid>
  )
}

export default FinishedRoundRow
