import { Button } from '@pancakeswap/uikit'
import Link from 'next/link'
import styled from 'styled-components'

interface LinkObj {
  title?: string
  titleKey: string
  'zh-CN': string
  'zh-HK': string
  en: string
}
interface LinkGroupObj {
  title?: string
  titleKey: string
  visibleLang: string[]
  items: LinkObj[]
}

const Footer = () => {
  // const router = useRouter()
  // const {t} = useTranslation('common')
  return (
    <Wrapper>
      <div className="left">
        <img style={{ width: 148, height: 88, flexShrink: 0 }} src="/images/blast/main_logo.png" className="main-logo" width="148px" height="88px" alt="" />
        <div className="contact">
          <div className="title">Contact us</div>
          <div className="contacts-wrap">
            <StyledLink href="https://github.com/BitSmile-Lab/LotteryContract" target="_blank" rel='"noopener noreferrer'>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="6" fill="#2B3222" />
                <ellipse cx="24" cy="24.75" rx="24" ry="23.25" fill="#5F6F4B" />
                <path d="M24.0002 0C10.747 0 0 11.0169 0 24.6076C0 35.4799 6.87679 44.7039 16.4128 47.9577C17.6123 48.1855 18.0526 47.4239 18.0526 46.7739C18.0526 46.1872 18.0304 44.2487 18.02 42.1925C11.3431 43.6811 9.93424 39.2891 9.93424 39.2891C8.84249 36.4448 7.26945 35.6885 7.26945 35.6885C5.09192 34.1613 7.43359 34.1926 7.43359 34.1926C9.84363 34.3662 11.1126 36.7285 11.1126 36.7285C13.2532 40.4904 16.7272 39.4028 18.0967 38.7741C18.3121 37.1836 18.9341 36.0981 19.6205 35.4836C14.2897 34.8613 8.6859 32.7513 8.6859 23.3224C8.6859 20.6358 9.62345 18.4406 11.1587 16.7174C10.9095 16.0976 10.088 13.5947 11.3912 10.2052C11.3912 10.2052 13.4066 9.54387 17.993 12.7276C19.9074 12.1824 21.9606 11.9089 24.0002 11.8996C26.0398 11.9089 28.0946 12.1824 30.0126 12.7276C34.5934 9.54387 36.606 10.2052 36.606 10.2052C37.9123 13.5947 37.0905 16.0976 36.8413 16.7174C38.3801 18.4406 39.3113 20.6358 39.3113 23.3224C39.3113 32.7738 33.6968 34.8548 28.3525 35.464C29.2133 36.2276 29.9804 37.7252 29.9804 40.021C29.9804 43.3135 29.9526 45.9634 29.9526 46.7739C29.9526 47.4288 30.3846 48.1961 31.6011 47.9544C41.132 44.697 48 35.4762 48 24.6076C48 11.0169 37.2546 0 24.0002 0Z" fill="#2B3222" />
              </svg>
            </StyledLink>

            <StyledLink href="https://twitter.com/BlastLotteries" target="_blank" rel='"noopener noreferrer'>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_83_592)">
                  <rect width="48" height="48" rx="6" fill="#2B3222" />
                  <path d="M29.8726 14.7703H33.004L26.1627 22.5897L34.2112 33.2298H27.9091L22.9735 26.7765L17.3258 33.2298H14.1924L21.5098 24.8662L13.7891 14.7703H20.2507L24.7123 20.6687L29.8726 14.7703ZM28.7735 31.3554H30.5088L19.3079 16.5463H17.4461L28.7735 31.3554Z" fill="#5F6F4B" />
                </g>
                <defs>
                  <clipPath id="clip0_83_592">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </StyledLink>
          </div>
        </div>
      </div>
      <div className="right">
        <Link href='/' passHref>
          Lottery
        </Link>
        <Link href='/how' passHref>
          How to Play
        </Link>
        {/* <BuyCyptoButton>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.705882" y="0.705882" width="22.5882" height="22.5882" rx="11.2941" stroke="url(#paint0_linear_83_574)" strokeWidth="1.41176" />
            <path d="M6.30742 10.8421H17.7231C17.8816 10.8421 18.0336 10.7791 18.1457 10.667C18.2578 10.555 18.3207 10.4029 18.3207 10.2444C18.3207 10.1543 18.3003 10.0654 18.2611 9.98432C18.2218 9.90323 18.1646 9.8321 18.0939 9.77628C18.0577 9.73002 18.0149 9.68934 17.9668 9.65555L13.7024 6.66288C13.6382 6.61754 13.5657 6.58531 13.4889 6.56805C13.4122 6.55079 13.3329 6.54883 13.2554 6.56229C13.178 6.57575 13.1039 6.60436 13.0375 6.64648C12.9711 6.68861 12.9137 6.74342 12.8685 6.80777C12.8234 6.87212 12.7914 6.94476 12.7743 7.02151C12.7572 7.09826 12.7555 7.17762 12.7692 7.25505C12.7829 7.33247 12.8117 7.40644 12.854 7.47271C12.8963 7.53898 12.9512 7.59625 13.0157 7.64125L15.8733 9.64668H6.30742C6.1489 9.64668 5.99687 9.70966 5.88478 9.82175C5.77269 9.93384 5.70972 10.0859 5.70972 10.2444C5.70972 10.4029 5.77269 10.5549 5.88478 10.667C5.99687 10.7791 6.1489 10.8421 6.30742 10.8421ZM17.7231 13.2282H6.30742C6.22894 13.2282 6.15122 13.2437 6.07871 13.2737C6.0062 13.3038 5.94031 13.3478 5.88482 13.4033C5.82932 13.4588 5.7853 13.5247 5.75526 13.5972C5.72523 13.6697 5.70977 13.7474 5.70977 13.8259C5.70971 13.916 5.7301 14.0049 5.76939 14.086C5.80867 14.1671 5.86584 14.2382 5.93658 14.294C5.97194 14.3391 6.01427 14.38 6.06368 14.4147L10.3281 17.4075C10.3923 17.4526 10.4648 17.4846 10.5414 17.5017C10.618 17.5188 10.6973 17.5207 10.7746 17.5071C10.8519 17.4936 10.9258 17.4649 10.992 17.4228C11.0583 17.3806 11.1156 17.3258 11.1606 17.2615C11.2517 17.1318 11.2875 16.9712 11.2601 16.8151C11.2328 16.6589 11.1445 16.5201 11.0148 16.429L8.15717 14.4236H17.7231C17.8816 14.4236 18.0336 14.3606 18.1457 14.2485C18.2578 14.1364 18.3207 13.9844 18.3207 13.8259C18.3207 13.6674 18.2578 13.5154 18.1457 13.4033C18.0336 13.2912 17.8816 13.2282 17.7231 13.2282Z" fill="#5F6F4B" />
            <path d="M6.30742 10.8421H17.7231C17.8816 10.8421 18.0336 10.7791 18.1457 10.667C18.2578 10.555 18.3207 10.4029 18.3207 10.2444C18.3207 10.1543 18.3003 10.0654 18.2611 9.98432C18.2218 9.90323 18.1646 9.8321 18.0939 9.77628C18.0577 9.73002 18.0149 9.68934 17.9668 9.65555L13.7024 6.66288C13.6382 6.61754 13.5657 6.58531 13.4889 6.56805C13.4122 6.55079 13.3329 6.54883 13.2554 6.56229C13.178 6.57575 13.1039 6.60436 13.0375 6.64648C12.9711 6.68861 12.9137 6.74342 12.8685 6.80777C12.8234 6.87212 12.7914 6.94476 12.7743 7.02151C12.7572 7.09826 12.7555 7.17762 12.7692 7.25505C12.7829 7.33247 12.8117 7.40644 12.854 7.47271C12.8963 7.53898 12.9512 7.59625 13.0157 7.64125L15.8733 9.64668H6.30742C6.1489 9.64668 5.99687 9.70966 5.88478 9.82175C5.77269 9.93384 5.70972 10.0859 5.70972 10.2444C5.70972 10.4029 5.77269 10.5549 5.88478 10.667C5.99687 10.7791 6.1489 10.8421 6.30742 10.8421ZM17.7231 13.2282H6.30742C6.22894 13.2282 6.15122 13.2437 6.07871 13.2737C6.0062 13.3038 5.94031 13.3478 5.88482 13.4033C5.82932 13.4588 5.7853 13.5247 5.75526 13.5972C5.72523 13.6697 5.70977 13.7474 5.70977 13.8259C5.70971 13.916 5.7301 14.0049 5.76939 14.086C5.80867 14.1671 5.86584 14.2382 5.93658 14.294C5.97194 14.3391 6.01427 14.38 6.06368 14.4147L10.3281 17.4075C10.3923 17.4526 10.4648 17.4846 10.5414 17.5017C10.618 17.5188 10.6973 17.5207 10.7746 17.5071C10.8519 17.4936 10.9258 17.4649 10.992 17.4228C11.0583 17.3806 11.1156 17.3258 11.1606 17.2615C11.2517 17.1318 11.2875 16.9712 11.2601 16.8151C11.2328 16.6589 11.1445 16.5201 11.0148 16.429L8.15717 14.4236H17.7231C17.8816 14.4236 18.0336 14.3606 18.1457 14.2485C18.2578 14.1364 18.3207 13.9844 18.3207 13.8259C18.3207 13.6674 18.2578 13.5154 18.1457 13.4033C18.0336 13.2912 17.8816 13.2282 17.7231 13.2282Z" fill="url(#paint1_linear_83_574)" />
            <defs>
              <linearGradient id="paint0_linear_83_574" x1="0" y1="0" x2="25.2687" y2="1.41976" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FC2B7E" />
                <stop offset="0.260417" stopColor="#FD744C" />
                <stop offset="0.453125" stopColor="#D4E12B" />
                <stop offset="0.614583" stopColor="#34D2D2" />
                <stop offset="0.776042" stopColor="#22CEFE" />
                <stop offset="1" stopColor="#22CEFE" />
              </linearGradient>
              <linearGradient id="paint1_linear_83_574" x1="5.70972" y1="6.55347" x2="18.9739" y2="7.4108" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FC2B7E" />
                <stop offset="0.260417" stopColor="#FD744C" />
                <stop offset="0.453125" stopColor="#D4E12B" />
                <stop offset="0.614583" stopColor="#34D2D2" />
                <stop offset="0.776042" stopColor="#22CEFE" />
                <stop offset="1" stopColor="#22CEFE" />
              </linearGradient>
            </defs>
          </svg>

          Buy USDT
        </BuyCyptoButton> */}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
    width: 100%;
    min-width: 1200px;
    position: relative;
    background: #14160E;
    padding: 80px 120px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: solid 1px #515B40;

    .left {
      >.contact {
        margin-left: 12px;

        .title {
          font-family: Alibaba PuHuiTi 2.0;
          font-size: 24px;
          font-weight: 800;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;
          color: #9BA48A;
          margin-bottom: 12px;
        }
        .contacts-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
    .right {
      width: 174px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      flex-direction: column;

      >a {
        font-family: Alibaba PuHuiTi 2.0;
        font-size: 24px;
        font-weight: 800;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: right;
        color: #5F6F4B;
        margin-bottom: 8px;
      }
    }
`

const StyledLink = styled.a`
    background: none;
    width: 48px;
    height: 48px;
    margin-right: 24px;
    cursor: pointer;
`

const BuyCyptoButton = styled(Button)`
    width: 174px;
    border-radius: 50px;
    padding: 0;
    height: 50px;
    background: #2b3222;
    font-family: Alibaba PuHuiTi 2.0;
    font-size: 24px;
    font-weight: 800;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F6F4B;
    margin-top: 32px;
    >svg {
      margin-right: 8px;
    }

`

export default Footer
