
import { Flex, Heading, PageSection } from '@pancakeswap/uikit'

import WithdrawCard, { WithdrawData } from './WithdrawCard'


const Withdrawal = ({ withdrawData }: { withdrawData: WithdrawData }) => {

  return <PageSection
    containerProps={{ style: { marginTop: '-30px' } }}
    background="#14160E"
    concaveDivider={undefined}
    clipFill={{ light: '#14160E' }}
    dividerPosition="top"
    index={2}
  >
    <Flex alignItems="center" justifyContent="center" flexDirection="column" pt="24px">
      <Heading fontSize="48px" scale="xl" color="#9BA48A" mb="10px" textAlign="center">
        Withdrawal Your Buying Tickets Assets
      </Heading>
      <WithdrawCard withdrawData={withdrawData} />
    </Flex>
  </PageSection>
}

export default Withdrawal