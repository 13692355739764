import { useTranslation } from '@pancakeswap/localization'
import { Balance, Flex, Skeleton, Text } from '@pancakeswap/uikit'
import { getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import BigNumber from 'bignumber.js'
import { useCakePrice } from 'hooks/useCakePrice'

interface RewardBracketDetailProps {
  cakeAmount: BigNumber
  rewardBracket?: number
  numberWinners?: string
  isBurn?: boolean
  isHistoricRound?: boolean
  isLoading?: boolean
}

const RewardBracketDetail: React.FC<React.PropsWithChildren<RewardBracketDetailProps>> = ({
  rewardBracket,
  cakeAmount,
  numberWinners,
  isHistoricRound,
  isBurn,
  isLoading,
}) => {
  const { t } = useTranslation()
  const cakePriceBusd = useCakePrice()

  const getRewardText = () => {
    const numberMatch = rewardBracket + 1
    if (isBurn) {
      return 'Burn'
    }
    if (rewardBracket === 5) {
      return `Match all ${numberMatch}`
    }
    return `Match first ${numberMatch}`
  }

  if (isBurn) {
    return <></>
  }

  return (
    <Flex width="100%" padding={isHistoricRound ? 0 : '0 24px'} flexDirection="row" justifyContent="space-between">
      {isLoading ? (
        <Skeleton mb="4px" mt="8px" height={16} width={80} />
      ) : (
        <Text
          fontSize="24px"
          bold
          style={{ width: 160 }}
          color={isBurn ? '#BF9B5E' : isHistoricRound ? '#E7BF5A' : '#04DDFB'}
        >
          {getRewardText()}
        </Text>
      )}
      {isHistoricRound && cakeAmount && (
        <Flex
          alignItems="center"
          justifyContent="center"
          style={{ width: 220, height: 40, borderRadius: 40, background: '#341716' }}
        >
          {/* {numberWinners !== '0' && (
            <Text bold fontSize="18px" color="#ffba27">
              {getFullDisplayBalance(cakeAmount.div(parseInt(numberWinners, 10)), 18, 8)} WETH {t('each')}
            </Text>
          )} */}
          <Text bold fontSize="18px" color="#ffba27">
            {numberWinners} {t('Winning Tickets')}
          </Text>
        </Flex>
      )}
      <Flex style={{ width: 120 }} justifyContent="flex-end">
        {isLoading || cakeAmount.isNaN() ? (
          <Skeleton my="4px" mr="10px" height={20} width={110} />
        ) : (
          <Balance
            fontSize="20px"
            bold
            color="#B4B4B4"
            prefix="~$"
            value={getBalanceNumber(cakeAmount.times(cakePriceBusd))}
            decimals={2}
          />
        )}
        {/* {isLoading || cakeAmount.isNaN() ? (
          <>
            <Skeleton mt="4px" mb="16px" height={12} width={70} />
          </>
        ) : (
          <Balance
            fontSize="12px"
            color="textSubtle"
            prefix="~$"
            value={getBalanceNumber(cakeAmount.times(cakePriceBusd))}
            decimals={4}
          />
        )} */}
      </Flex>
    </Flex>
  )
}

export default RewardBracketDetail
