import { useTranslation } from '@pancakeswap/localization'
import { Flex, Heading, PageSection, Skeleton, Text } from '@pancakeswap/uikit'
import { formatUnits } from '@pancakeswap/utils/viem/formatUnits'
import Footer from 'components/Footer'
import { lotteryV2ABI } from 'config/abi/lotteryV2'
import { LotteryStatus } from 'config/constants/types'
import useTheme from 'hooks/useTheme'
import { useMemo, useState } from 'react'
import { useFetchLottery, useLottery } from 'state/lottery/hooks'
import { styled } from 'styled-components'
import { getLotteryV2Address } from 'utils/addressHelpers'
import { useAccount, useContractRead } from 'wagmi'
import AllHistoryCard from './components/AllHistoryCard'
import CheckPrizesSection from './components/CheckPrizesSection'
import Countdown from './components/Countdown'
import Hero from './components/Hero'
import HistoryTabMenu from './components/HistoryTabMenu'
import NextDrawCard from './components/NextDrawCard'
import Withdrawal from './components/Withdrawal/Withdrawal'
import YourHistoryCard from './components/YourHistoryCard'
import useGetNextLotteryEvent from './hooks/useGetNextLotteryEvent'
import useShowMoreUserHistory from './hooks/useShowMoreUserRounds'
import useStatusTransitions from './hooks/useStatusTransitions'

const LotteryPage = styled.div`
  min-height: calc(100vh - 64px);
`

const Lottery = () => {
  useFetchLottery()
  useStatusTransitions()
  const { address: account } = useAccount()
  const { t } = useTranslation()
  const { isDark, theme } = useTheme()
  const {
    currentRound: { status, endTime },
  } = useLottery()
  const [historyTabMenuIndex, setHistoryTabMenuIndex] = useState(0)
  const endTimeAsInt = parseInt(endTime, 10)
  const { nextEventTime, postCountdownText, preCountdownText } = useGetNextLotteryEvent(endTimeAsInt, status)
  const { numUserRoundsRequested, handleShowMoreUserRounds } = useShowMoreUserHistory()

  const { data, isFetching, refetch } = useContractRead({
    address: getLotteryV2Address(),
    abi: lotteryV2ABI,
    functionName: 'viewUserAssets',
    args: [account!],
    watch: true,
    structuralSharing: true
  })

  const withdrawData = useMemo(() => {
    if (!data?.length) {
      return {
        amount: '0', lastAttendedLotteryId: '0', awaitRound: '0', refetch: () => { },
        hasData: false
      }
    }

    const [_amount, _lastAttendedLotteryId, _awaitRound] = data
    return {
      amount: formatUnits(_amount, 18),
      lastAttendedLotteryId: _lastAttendedLotteryId.toString(),
      awaitRound: _awaitRound.toString(),
      refetch,
      hasData: true
    }
  }, [data, isFetching, refetch])

  return (
    <>
      <LotteryPage>
        <PageSection background="#14160E" index={1} hasCurvedDivider={false}>
          <Hero />
        </PageSection>


        <Withdrawal withdrawData={withdrawData} />

        <PageSection
          containerProps={{ style: { marginTop: '-30px' } }}
          background="#14160E"
          concaveDivider={undefined}
          clipFill={{ light: '#14160E' }}
          dividerPosition="top"
          index={2}
        >
          <Flex alignItems="center" justifyContent="center" flexDirection="column" pt="24px">
            {status === LotteryStatus.OPEN && (
              <Heading fontSize="48px" scale="xl" color="#9BA48A" mb="10px" textAlign="center">
                Seize the opportunity now!
              </Heading>
            )}
            <Flex alignItems="center" justifyContent="center" mb="48px">
              {nextEventTime && (postCountdownText || preCountdownText) ? (
                <Countdown
                  nextEventTime={nextEventTime}
                  postCountdownText={postCountdownText}
                  preCountdownText={preCountdownText}
                />
              ) : (
                <Skeleton height="41px" width="250px" />
              )}
            </Flex>
            <NextDrawCard />
          </Flex>
        </PageSection>
        <PageSection background="#14160E" hasCurvedDivider={false} index={2}>
          <CheckPrizesSection />
        </PageSection>
        <PageSection
          position="relative"
          innerProps={{ style: { margin: '0', width: '100%' } }}
          background="#14160E"
          hasCurvedDivider={false}
          index={2}
        >
          <HistoryTabMenu activeIndex={historyTabMenuIndex} setActiveIndex={(index) => setHistoryTabMenuIndex(index)} />
          <Flex width="100%" flexDirection="column" alignItems="center" justifyContent="center">
            <Text bold fontSize="48px" color="#9BA48A" mb="84px">
              {t('Finished Rounds')}
            </Text>
            {historyTabMenuIndex === 0 ? (
              <AllHistoryCard />
            ) : (
              <YourHistoryCard
                handleShowMoreClick={handleShowMoreUserRounds}
                numUserRoundsRequested={numUserRoundsRequested}
              />
            )}
          </Flex>
        </PageSection>
        {/* <PageSection
          dividerPosition="top"
          dividerFill={{ light: theme.colors.background }}
          clipFill={{ light: '#9A9FD0', dark: '#66578D' }}
          index={2}
        >
          <HowToPlay />
        </PageSection>
        <LotterySubgraphHealthIndicator /> */}
        <Footer />
      </LotteryPage>
    </>
  )
}

export default Lottery
