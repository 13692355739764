import { Flex } from '@pancakeswap/uikit'
import { useEffect } from 'react'
import { useGetLotteryGraphDataById } from 'state/lottery/hooks'
import { LotteryRound } from 'state/types'
import { styled } from 'styled-components'
import RewardBrackets from '../RewardBrackets'

const NextDrawWrapper = styled(Flex)`
  width: 600px;
  padding: 24px;
  flex-direction: column;
`

const PreviousRoundCardFooter: React.FC<
  React.PropsWithChildren<{ lotteryNodeData: LotteryRound; lotteryId: string }>
> = ({ lotteryNodeData, lotteryId }) => {
  const lotteryGraphDataFromState = useGetLotteryGraphDataById(lotteryId)

  useEffect(() => {
    const getGraphData = async () => {}
    if (!lotteryGraphDataFromState) {
      getGraphData()
    }
  }, [lotteryGraphDataFromState, lotteryId])

  return (
    <NextDrawWrapper>
      <RewardBrackets lotteryNodeData={lotteryNodeData} isHistoricRound />
    </NextDrawWrapper>
  )
}

export default PreviousRoundCardFooter
